<i18n>
ru:
  search: Выбор опций блюда
  stop: Распродано
  allOptionsDisabled: Извините, все опции недоступны
  someOptionsDisabled: Извините, часть опций недоступна
ua:
  search: Вибір опцій страви
  stop: Розпродано
  allOptionsDisabled: Вибачте, всі опції недоступні
  someOptionsDisabled: Вибачте, частина опцій недоступна
us:
  search: Select option
  stop: Sold out
  allOptionsDisabled: Sorry, all options are unavailable
  someOptionsDisabled: Sorry, some options are unavailable
</i18n>

<template>
  <ClientOnly v-if="!allOptionInStopList">
    <arora-option-slider
      v-if="objectKeys(optionsLocal).length > 1"
      :key="`first-${product?.ID}-${uid}`"
      :disabled="
        (!!product && menuStore.StopListProductIds.has(product.ID)) ||
        disableFirstLevel
      "
      :data-test-id="dataTestId"
      :disabled-text="translate('optionSelector.stop')"
      :index-enabled="indexEnabledFirstLevel"
      :label="translate('optionSelector.search')"
      :options="firstLevels"
      :theme="theme"
      :threshold="threshold"
      v-model:selected="selectedOptionFirstLevel"
    >
      <template #option="option: { value: GUID }">
        {{ option.value }}
      </template>
    </arora-option-slider>

    <div class="v-elements-overlay v-w-100">
      <div
        v-if="showLoader"
        class="v-overlay-element"
      >
        <common-skeleton :height="50" />
      </div>
      <div
        v-if="!showLoader"
        class="v-overlay-element"
      >
        <arora-option-slider
          v-if="
            optionsLocal[selectedOptionFirstLevel]?.length > 0 &&
            optionsLocal[selectedOptionFirstLevel].every(
              (o) => !stringIsNullOrWhitespace(useName ? o.Name : o.Description)
            )
          "
          :key="`second-${product?.ID}-${uid}`"
          :data-test-id="dataTestId"
          :disabled="!!product && menuStore.StopListProductIds.has(product.ID)"
          :disabled-text="translate('optionSelector.stop')"
          :index-enabled="indexEnabled"
          :label="translate('optionSelector.search')"
          :options="optionsLocal[selectedOptionFirstLevel]"
          :theme="theme"
          :threshold="threshold"
          v-model:selected="selectedOptionLocal"
        >
          <template #option="option: { value: Option | undefined }">
            <span
              v-if="option.value"
              v-html="useName ? option.value.Name : option.value.Description"
            />
          </template>
        </arora-option-slider>
      </div>
    </div>
  </ClientOnly>
  <div
    v-if="showWarning"
    class="v-d-flex v-align-items-center v-mt-xs"
  >
    <icon-old-general-information-circle
      class="v-error-color v-disabled__option__icon v-mr-xs"
    />
    <span
      class="v-error-color"
      v-html="
        translate(
          allOptionInStopList
            ? 'optionSelector.allOptionsDisabled'
            : 'optionSelector.someOptionsDisabled'
        )
      "
    />
  </div>
</template>

<script setup lang="ts">
import type { Option, ProductInList } from '~types/menuStore'
import type { OptionTheme } from '~types/props'

import { type AutoTest, type GUID, useCommon } from '@arora/common'

import { Guid } from '~api/consts'

const { options, product } = defineProps<
  AutoTest & {
    product: ProductInList | null | undefined
    options: Record<GUID, Option[]>
    threshold: number
    useName?: boolean
    theme?: OptionTheme | null
  }
>()

const appConfig = useAppConfig()
const menuStore = useMenuStore()
const { objectKeys } = useCommon()
const { translate } = useI18nSanitized()
const { stringIsNullOrWhitespace } = useCommon()

const { applyProductsFilter, setProductModifiersToZero } = useProduct()
const uid = useId()

const optionsLocal = ref<Record<GUID, Option[]>>({})
const firstLevels = ref<GUID[]>([])
const showLoader = ref<boolean>(false)

function indexEnabledFirstLevel(firstLevel: GUID): boolean {
  return !optionsLocal.value[firstLevel].every(
    (option) =>
      !applyProductsFilter(option) || menuStore.StopListOptionIds.has(option.ID)
  )
}
function indexEnabled(option: Option | undefined): boolean {
  return option
    ? applyProductsFilter(option) && !menuStore.StopListOptionIds.has(option.ID)
    : false
}

const disableFirstLevel = ref<boolean>(false)

onMounted(() => {
  const keys = objectKeys(options)

  firstLevels.value = Array.from({ length: keys.length })

  const firstLevelWithSWeight: { key: GUID; SWeight: number }[] = []

  for (const key of keys) {
    const availableOptionsInGroupTotal: Option[] = (options[key] ?? [])
      .filter((item: Option) => {
        return (
          !menuStore.NonActiveProductIds.has(item.ID) && applyProductsFilter(item)
        )
      })
      .sort((a, b) => a.SWeight - b.SWeight)

    if (availableOptionsInGroupTotal.length > 0) {
      const availableOptionsInGroup = [
        ...availableOptionsInGroupTotal.filter(
          (option) => !menuStore.StopListOptionIds.has(option.ID)
        ),
        ...availableOptionsInGroupTotal.filter((option) =>
          menuStore.StopListOptionIds.has(option.ID)
        )
      ]
      const sWeight = (
        availableOptionsInGroup.map((item) => item.SWeight) ?? []
      ).reduce((item1, item2) => {
        return item1 + item2
      })
      firstLevelWithSWeight.push({ key: key, SWeight: sWeight })
      if (appConfig.VueSettingsPreRun.MenuPageHideStopList) {
        if (
          !availableOptionsInGroup.every((option: Option) =>
            menuStore.StopListOptionIds.has(option.ID)
          )
        ) {
          optionsLocal.value[key as GUID] = availableOptionsInGroup
        }
      } else {
        optionsLocal.value[key as GUID] = availableOptionsInGroup
      }
    }
  }

  firstLevels.value = firstLevelWithSWeight
    .sort((a, b) => {
      return a.SWeight >= b.SWeight ? 1 : -1
    })
    .map((fl) => fl.key)
})

const selectedOptionLocal = computed<Option | undefined>({
  get() {
    if (product) return menuStore.SelectedOptionsPerProduct.get(product.ID)

    return
  },
  set(value) {
    disableFirstLevel.value = true
    if (value) menuStore.setSelectedOption(product, value.Title as GUID, value.ID)

    setTimeout(() => {
      disableFirstLevel.value = false
    }, 300)
  }
})

const selectedOptionFirstLevel = computed<GUID>({
  get() {
    if (product)
      return (menuStore.SelectedOptionsPerProduct.get(product.ID)?.Title ??
        '') as GUID

    return '' as GUID
  },
  set(value) {
    showLoader.value = true

    const optionsFiltered = optionsLocal.value[value].filter(
      (option: Option) =>
        !menuStore.NonActiveProductIds.has(option.ID) &&
        !menuStore.StopListOptionIds.has(option.ID)
    )

    const nonStopListActiveOptionID: GUID =
      optionsFiltered.length > 0 ? optionsFiltered[0].ID : Guid.Empty

    menuStore.setSelectedOption(product, value, nonStopListActiveOptionID)

    setTimeout(() => {
      showLoader.value = false
    }, 300)
  }
})

watch(
  () => menuStore.SelectedOptionsPerProduct,
  (value, oldValue) => {
    if (
      value.get(product?.ID ?? Guid.Empty) !==
      oldValue?.get(product?.ID ?? Guid.Empty)
    ) {
      setProductModifiersToZero(product?.ID ?? Guid.Empty)
    }
  },
  { deep: true, immediate: true }
)

const showWarning = computed<boolean>(() => {
  for (const option of Object.values(options)) {
    if (option.some((option) => menuStore.StopListOptionIds.has(option.ID)))
      return true
  }
  return false
})

const allOptionInStopList = computed<boolean>(
  () =>
    (product && menuStore.StopListProductIds.has(product.ID)) ||
    ([] as Option[]).concat
      .apply([], Object.values(optionsLocal.value))
      .every((option) => menuStore.StopListOptionIds.has(option.ID))
)
</script>

<style scoped lang="scss">
.v-disabled__option__icon {
  width: 20px;
  height: 20px;
}
</style>
